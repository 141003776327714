import deFormError from '../../bin/json/lang/app/de/form/error.json';
import deApplicationFrontend from '../../bin/json/lang/app/de/application/frontend.json';
import deAccount from '../../bin/json/lang/app/de/account.json';
import deApplication from '../../bin/json/lang/app/de/application.json';

import enFormError from '../../bin/json/lang/app/en/form/error.json';
import enApplicationFrontend from '../../bin/json/lang/app/en/application/frontend.json';
import enAccount from '../../bin/json/lang/app/en/account.json';
import enApplication from '../../bin/json/lang/app/en/application.json';


const langs = {
	'de/form/error': () => deFormError,
	'de/application/frontend': () => deApplicationFrontend,
	'de/account': () => deAccount,
	'de/application': () => deApplication,

	'en/form/error': () => enFormError,
	'en/application/frontend': () => enApplicationFrontend,
	'en/account': () => enAccount,
	'en/application': () => enApplication,
};

// eslint-disable-next-line piggyback/no-restricted-global-extend
window.appLangs = langs;

export default langs;
